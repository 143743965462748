import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import makro from './images/makro.jpg';
import makro1 from './images/makro1.jpg';
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal.css';

export default function Modal1(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Макропіл
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-center'>Італійський пілінг Makro Peel - відмінний вибір, коли хочеться досягти WOW-ефекту</h4>
        <Col sm={9}>
        <p>
          <ul>Він забезпечує:<br/>
        <li>💠виражений ліфтинг</li><br/>
        <li>💠ексфоліацію</li><br/>
        <li>💠ревіталізація</li><br/>
        <li>💠освітлення та зменшення та усунення поверхневої пігментації</li><br/>
</ul></p>
</Col>
<Col sm={10}> {/* Уменьшил размер для умещения двух изображений */}
  <div style={{ display: 'flex', justifyContent: 'space-around' }}>
    <div style={{ maxWidth: '400px', maxHeight: '650px', padding: '1%' }}>
      <img
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        src={makro}
        alt="Кабінет"
      />
    </div>
    <div style={{ maxWidth: '400px', maxHeight: '650px', padding: '1%' }}>
      <img
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        src={makro1}
        alt="Кабінет"
      />
    </div>
  </div>
</Col>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Закрити</Button>
      </Modal.Footer>
    </Modal>
  );
}

function App() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}