import { Carousel } from 'react-bootstrap';
import home1 from './images/1.png';
import home2 from './images/effecthelthy.png';
import home3 from './images/2024.png';

export default function Slider() {
  return (
    <Carousel style={{ maxWidth: '1050px', maxHeight: '600px', padding: '1%' }}>
    <Carousel.Item>
    <img
          style={{ maxWidth: '1050px', maxHeight: '600px', padding: '1%' }}
          className="d-block w-100"
          src={home1}
          alt="Кабінет"
        />
    </Carousel.Item>
    <Carousel.Item>
    <img
          style={{ maxWidth: '1050px', maxHeight: '600px', padding: '1%' }}
          className="d-block w-100"
          src={home2}
          alt="Кабінет"
        />
    </Carousel.Item>
    <Carousel.Item>
    <img
          style={{ maxWidth: '1050px', maxHeight: '600px', padding: '1%' }}
          className="d-block w-100"
          src={home3}
          alt="Кабінет"
        />
    </Carousel.Item>
  </Carousel>
  );
}