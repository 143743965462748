import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, ListGroup, ListGroupItem, Form, Button } from 'react-bootstrap';
import Iframe from 'react-iframe';
import axios from 'axios';

export default function ContactGrid() {
  const [name, setName] = useState('');
  const [review, setReview] = useState('');

  // Обработчики изменений полей формы
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleReviewChange = (e) => {
    setReview(e.target.value);
  };

  const handleLeaveReview = () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('review', review);

    axios.post('/server.php', formData)
      .then((response) => {
        showNotification();
        resetForm();
      })
      .catch((error) => {
        console.error('Ошибка при отправке формы:', error);
      });
  };

  const showNotification = () => {
    const notification = document.createElement("div");
    notification.classList.add("alert", "alert-success");
    notification.innerHTML = "Дякуюжмо за ваш відгук, ви робите нас краще ♥";

    const container = document.querySelector(".review-form");
    container.appendChild(notification);

    setTimeout(() => {
      notification.remove();
    }, 5000);
  };

  const resetForm = () => {
    setName('');
    setReview('');
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Ім'я</Form.Label>
              <Form.Control className='mb-4' type="text" placeholder="Введіть Ваше ім'я" value={name} onChange={handleNameChange} />
            </Form.Group>
            <Form.Group controlId="formReview">
              <Form.Label>Залишити відгук</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Залиште свій відгук" value={review} onChange={handleReviewChange} />
            </Form.Group>
            <Button variant="primary mb-4 mt-4" onClick={handleLeaveReview}>
              Залишити відгук
            </Button>
          </Form>
        </Col>
      </Row>

      <h2 className="text-center mb-4">Контакти</h2>
    <Row>   
        <Col md={6}>
          <Card>
            <Card.Header as="h5">Черкаси</Card.Header>
            <Card.Body>
              <ListGroup className="mb-2">
                <ListGroupItem><a href="tel:+380677621000">+38(067)762-10-00</a></ListGroupItem>
                <ListGroupItem><a href="tel:+380507621000">+38(050)762-10-00</a></ListGroupItem>
              </ListGroup>
              <Iframe
                url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2594.2518835774013!2d32.052320212423844!3d49.44195615934282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d14d38fd345795%3A0xcd9a1d4a520369d0!2sOndaSonica!5e0!3m2!1suk!2sua!4v1706615568744!5m2!1suk!2sua"
                width="100%"
                height="300px"
                display="initial"
                position="relative"
                allowFullScreen
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Header as="h5">Одеса</Card.Header>
            <Card.Body>
              <ListGroup className="mb-2">
                <ListGroupItem><a href="tel:+380985048080">+38(098)504-80-80</a></ListGroupItem>
                <ListGroupItem><a href="tel:+380995048080">+38(099)504-80-80</a></ListGroupItem>
              </ListGroup>
              <Iframe
                url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2750.3773189913!2d30.754823912292558!3d46.4214003689453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c63574bb167e6b%3A0xaa9898f92eacc832!2sOndaSonica!5e0!3m2!1suk!2sua!4v1706615895064!5m2!1suk!2sua"
                width="100%"
                height="300px"
                display="initial"
                position="relative"
                allowFullScreen
              />
            </Card.Body>
          </Card>
        </Col>
        <Col >
          </Col>
      </Row>
  </Container>
)
}
