import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal.css';

export default function Modal6(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Мигдалевий пілінг з ДмАЄ
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Основні властивості Mandelico Peeling</h4>
        <Col sm={12}>
        <p>
        Мигдальна кислота в складі пілінгу доповнена диметиламіноетанолом (ДМАЕ) — попередником нейромедіатора ацетилхоліну, який забезпечує виражений ліфтинг-ефект після процедури.
        <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Показання</Accordion.Header>
        <Accordion.Body>
        Знижений тургор (атонія)/тяжність шкіри<br/>
        Безліч дрібних зморщок<br/>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <Accordion>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Склад</Accordion.Header>
        <Accordion.Body>
        Мигдальна кислота 50%, ДМАЕ 2%
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <Accordion>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Ефективність і переваги
</Accordion.Header>
        <Accordion.Body>
        Миттєвий ліфтинг - ефект<br/>
        Процедура вихідного дня<br/>
        Ефективна підготовка шкіри до агресивніших пілінгів (зіродинним і глибоким).
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <Accordion>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Протипоказання</Accordion.Header>
        <Accordion.Body>
        Індивідуальна нестерпність до компонентів<br/>
        Період вагітності та лактації<br/>
        Епілепсії<br/>
        Психічних розладів<br/>
        Гіпертонічна хвороба в стадії декомпенсації<br/>
        Аутоімунні захворювання в стадії декомпенсації<br/>
        Інфекційні (вірусні та бактеріальні)<br/>
        Захворювання в стадії загострення<br/>
        Онкологічні захворювання — після консультації з лікарем.
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
          Сфера застосування Mandelico Peeling
          Обличчя, шия, декольте.
        </p>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Закрити</Button>
      </Modal.Footer>
    </Modal>
  );
}

function App() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}