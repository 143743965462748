import React from "react";
import Footer from './Components/Footer';
import AboutGrid from  './Components/AboutGrid';


export const About = () => (
    <>
    <AboutGrid/>
    <Footer />
  </>
);