import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal.css';
import Col from 'react-bootstrap/Col';


export default function Modal3(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">MCA-35</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <Col sm={12}>
        Інноваційний пілінг, що містить модифіковану трихлороцтову кислоту –
        монохлороцтову кислоту (MCA), а також арбутин і саліцилову кислоту. <br/>
        Пілінг МСА 35 сприяє до інтенсивній стимуляції, 
        реструктуризації та омолодженню всіх шарів шкіри.<br/>
        Це новітня розробка іспанської лабораторії <a href='https://totis.ua/brand/innoaesthetics/'><strong>Innoaesthetics</strong></a> для поліпшення текстури, 
        тургору і реструктуризації шкіри.
        <br/>
        <strong>Показання:</strong><br/>
        <ul>
        <li>в'ялість</li><br/>
        <li>гіперкератоз</li> <br/>
        <li>нерівний рельєф</li><br/>
        <li>тьмяний колір шкіри</li><br/>
        </ul>
        Біоревіталізація без ін'єкцій: невже це можливо?<br/>
        МСА 35 від Innoaesthetics – поверхнево-серединний пілінг, який прийшов на заміну відомому ТСА-пілінгу і відрізняється 
        від них більшою безпекою і меншим періодом відновлення при збереженні виключної ефективності.
       
       
       </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Закрити</Button>
      </Modal.Footer>
    </Modal>
  );
}

function App() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}