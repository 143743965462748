import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import Col from 'react-bootstrap/Col';
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal.css';

export default function Modal9(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Відмінності професійної чистки обличчя та чистки в домашніх умовах        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col sm={12}>
        <p>
        Основна відмінність професійної процедури від домашньої полягає в глибині та якості очищення. Вдома ефективно та безпечно провести глибоку чистку практично неможливо. 
        По-перше, для цього потрібне спеціальне обладнання, а, по-друге, знання та досвід косметолога, щоб підібрати необхідну процедуру та не пошкодити шкіру неправильним доглядом. 
        Дуже часто, щоб ефект був максимальним, поєднують кілька видів чистки. Наприклад, спочатку проводять механічну, а потім ультразвукову чистку.
        В домашніх умовах можна використовувати скраби та пілінги. Залежно від типу шкіри їх рекомендують проводити 1-3 рази на тиждень. 
        Якщо шкіра жирна, скраб можна використовувати частіше, а якщо суха, 1-2 разів на тиждень буде достатньо.
        Також можна робити спеціальні очисні маски. При цьому потрібно не забувати, що наносити їх рекомендують на розпарене обличчя та відкриті пори, 
        а після процедури шкіру змащувати легким заспокійливим та зволожуючим кремом. Однак всі ці процедури не можуть замінити професійну чистку, але дозволяють зберегти її ефект на більш довгий час.
        </p>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Закрити</Button>
      </Modal.Footer>
    </Modal>
  );
}

function App() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}