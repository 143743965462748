import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactGrid from "./Components/ContactGrid";
import Footer from "./Components/Footer";

export const Contact = () => (
        <>
      <ContactGrid/>
      <Footer/>
      </>
      );