import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import Col from 'react-bootstrap/Col';
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal.css';
import kemikum from './images/kemikum.jpg';
import Accordion from 'react-bootstrap/Accordion';



export default function Modal5(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Kemikum        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-center'>Ексклюзивний пептидний пілінг-біоревіталізант</h4>
        <Col sm={12}>
        <p>
        Kemikum – хімічний пілінг з абсолютно унікальною та інноваційною формулою.
        Його можна розглядати як медичний засіб, який не тільки дає яскраво виражений ефект омолодження і ліфтингу, але і є потужним регенерантом, антибактеріальним та освітлюючим засобом.
        Основна дія пілінгу Kemikum пов'язана з трихлороцтовою кислотою (TCA), яка, на відміну від класичних пілінгів впливає на глибокі шари поверхонь.
        <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Детальніше</Accordion.Header>
        <Accordion.Body>
        Даний механізм обумовлений наявністю в препараті озонідів - сполук озону та оливкової олії, так звані капсули озону в олії, 
        які завдяки своїй максимально низькій кислотності, нейтралізують ТСА, при цьому не викликаючи процесів окиснення та коагуляції білків. 
        Доставляючи кисень у глибокі шари дерми, озоніди посилюють процеси стимуляції фібробластів, інтенсивної регенерації та насичення шкіри киснем.
        Аргірелін у складі пілінгу має ботуліноподібну дію, а койєва та мигдальна кислота освітлюють шкіру та оновлюють клітини епідермісу.
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
        

        </p>
        </Col>
        <Col sm={12}>
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div style={{ maxWidth: '500px', maxHeight: '325px', padding: '1%' }}>
      <img
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        src={kemikum}
        alt="Кабінет"
      />
    </div>
    </div>
    </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Закрити</Button>
      </Modal.Footer>
    </Modal>
  );
}

function App() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}