import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import Col from 'react-bootstrap/Col';
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal.css';

export default function Modal7(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Механічна чистка       </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col sm={12}>
        <p>
        Механічна чистка передбачає видалення ороговілого шару шкіри та очищення пір за допомогою спеціальних інструментів (косметичних ложечок, сита та ін.). 
        Перед цим обличчя очищають від залишків макіяжу, а для розкриття пір розпарюють або наносять спеціальну маску. Також шкіру знезаражують за допомогою антисептика.
        Після проведення чистки роблять заспокійливу або зволожуючу маску.
        Механічне чищення рекомендують при жирному типі шкіри, вираженому акне та забитих порах. Але людям з сухою та чутливою шкірою вона не підходить.
        </p>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Закрити</Button>
      </Modal.Footer>
    </Modal>
  );
}

function App() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}