import React from 'react';
import './NavList.css';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { FaFacebook, FaTwitter, FaInstagram, FaTiktok } from 'react-icons/fa';

class Footer extends React.Component {
  render() {
    const footerStyle = {
      backgroundColor: '#f8f9fa',
      padding: '50px 0',
      color: '#495057',
      textAlign: 'center',      
    };

    const socialIconsStyle = {
      fontSize: '24px',
      margin: '0 10px',
      color: '#007bff',
    };

    return (
      <footer style={footerStyle}>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h4>Навігація по сайту</h4>
              <Nav.Link as={Link} to="/">Головна</Nav.Link>
              <Nav.Link as={Link} to="/About">Про нас</Nav.Link>
              <Nav.Link as={Link} to="/Service">Послуги</Nav.Link>
              <Nav.Link as={Link} to="/Devices">Наші прилади</Nav.Link>
              <Nav.Link as={Link} to="/Contact">Контакти</Nav.Link>
            </div>
            <div className="col-md-4">
              <h4>Зв'яжіться з нами</h4>
              <p> <strong>Черкаси:</strong><a href="tel:+380677621000">+38(067)762-10-00</a> , <a href="tel:+380507621000">+38(050)762-10-00</a> <br/>
                 <strong>Одеса:</strong> <a href="tel:+380985048080">+38(098)504-80-80</a>, <a href="tel:+380995048080">+38(099)504-80-80</a>  <br/>
                 <a href="mailto:ondasonica2023@gmail.com">Email: ondasonica2023@gmail.com</a></p>
            </div>
            <div className="col-md-4">
              <h4>Ми у соц. мережах</h4>
              <p>
                <a href="https://www.facebook.com/profile.php?id=61554426881871" target="_blank" rel="noopener noreferrer">
                  <FaFacebook style={socialIconsStyle} />
                </a>
                <a href="https://www.tiktok.com/@ondasonica.odesa?_t=8ji0A6haVsb&_r=1" target="_blank" rel="noopener noreferrer">
                  <FaTiktok style={socialIconsStyle} />
                </a>
                <a href="https://www.instagram.com/onda.sonica?fbclid=IwAR2HG0tc2dZv2uWMx2HMLyouLHDERMCpWHHm2WeqEerVGSzOZl-2oUyvx_w" target="_blank" rel="noopener noreferrer">
                  <FaInstagram style={socialIconsStyle} />
                </a>
              </p>
            </div>
          </div>
          <hr />
          <p>&copy; {new Date().getFullYear()} Onda Sonica. All rights reserved.</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
