import React from "react";
import ServiceGrid from "./Components/ServiceGrid";
import Footer from "./Components/Footer";
import './Components/css/style1.css';
import './Components/css/style1.scss';

export const Service = () => (

    <>
    <ServiceGrid/>
    <Footer/>
    </>
);