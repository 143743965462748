import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import kab from './images/kab.jpg';
import Col from 'react-bootstrap/Col';
import cactor from './images/c-actor.png';
import dactor from './images/d-actor.png';
import vactor from './images/v-actor.png';
import vacuactor from './images/vacu.png';
import magn from './images/magn.jpg';



export default function DevicesGrid() {
    
return(
    <Tabs
    defaultActiveKey="home"
    id="fill-tab-example"
    className="mb-3"
    fill
  >
        <Tab eventKey="home" title="DUOLITH SD1 »ULTRA« AWT">
        <Container>
      <Row>
        <h2>DUOLITH SD1 »ULTRA« AWT</h2>
        <Col sm={6}> {/* Увеличил размер для текстового блока */}
          <img
            style={{ maxHeight: '400px', padding: '1%' }}
            className="d-block w-100"
            src={kab}
            alt="Кабінет"
          />
        </Col>
        <Col sm={4}>
          <p>
            Duolith SD1 »ULTRA« AWT — це передова методика лікування, що базується на використанні ультразвукових хвиль. Ця технологія, реалізована у системі DUOLITH SD1, надає вражаючі результати в області реабілітації та лікування різноманітних захворювань, включаючи проблеми з м'язово-скелетною системою та судинні розлади.
          </p>
        </Col>
        <h3>Переваги DUOLITH SD1 »ULTRA« AWT:</h3>
        <ol>
          <li>
            <strong>Ефективність лікування:</strong> DUOLITH SD1 »ULTRA« AWT забезпечує високоефективне лікування різноманітних станів, включаючи болі в області суглобів, м'язів та судинні захворювання.
          </li>
          <li>
            <strong>Безпека та мінімальна інвазивність:</strong> Ця технологія має мінімальний ступінь інвазивності, що сприяє швидкому відновленню пацієнтів. Відсутність хірургічного втручання зменшує ризик ускладнень.
          </li>
          <li>
            <strong>Ультразвукова терапія:</strong> Система DUOLITH SD1 базується на застосуванні ультразвукових хвиль, які поліпшують кровообіг, регенерацію тканин та зменшують біль.
          </li>
          <li>
            <strong>Інноваційні технології:</strong> DUOLITH SD1 »ULTRA« AWT використовує передові методики та технології для досягнення оптимальних результатів лікування, роблячи його видающимся в своєму роді.
          </li>
        </ol>
        <ul>
        <img
            style={{ maxHeight: '400px',maxWidth:'400px', padding: '1%' }}
            className="d-block w-100"
            src={cactor}
            alt="Кабінет"
          />
            <li>C-ACTOR</li><br/>
                      Для процедур з використанням розфокусованих плоских акустичних хвиль®
              Лікування за допомогою плоских акустичних хвиль має кілька переваг:
              Безболісне лікування: лікування плоскими акустичними хвилями є безболісним процесом. Не потрібна анестезія, і більшість пацієнтів не відчуває жодного дискомфорту під час процедури.
              Мінімальний інвазивний вплив: лікування плоскими акустичними хвилями є мінімально інвазивним методом, що означає, що не потрібна хірургічна інтервенція або введення голки. Це дозволяє уникнути ризику інфекції та скоротити час відновлення.
              Зменшення часу лікування: лікування плоскими акустичними хвилями може зменшити час лікування порівняно з іншими методами, що може бути корисним для пацієнтів, які шукають швидке та ефективне лікування.
              Результати без лікарських засобів: лікування плоскими акустичними хвилями може допомогти досягти результатів без використання лікарських засобів, що дозволяє уникнути побічних ефектів та зменшити ризик алергічної реакції на препарати.
              Широкий спектр застосування: використовування плоских акустичних хвиль може застосовуватися для лікування таких захворювань як, діабетична стопа, трофічна виразка внаслідок атеросклерозу судин, целюліт 1-4 стадії, опіки, кілоїдні рубці, хвороба Пейроні, псоріаз, розацеа, контрактура Дюпіітрена, розсмоктування гемотом.
            <img
            style={{ maxHeight: '400px',maxWidth:'400px', padding: '1%' }}
            className="d-block w-100"
            src={dactor}
            alt="Кабінет"
            />
              <li>D-ACTOR</li>
              dactor
              Для обробки радіальних акустичних хвиль®
              Лікування за допомогою алікатора D-ACTOR має кілька переваг:
              Швидке та ефективне лікування: за рахунок високоінтенсивних ударних хвиль, які глибоко проникають в м'язову тканину. Це дозволяє зменшити час лікування та покращити результати.
              Мінімальний інвазивний вплив: є мінімально інвазивним методом лікування, який означає, що не потрібне хірургічне втручання або введення голки. Це дозволяє уникнути ризику інфекції та скоротити час відновлення.
              Зменшення болю: зменшує біль, пов'язаний з травмами м'язів та суглобів, сприяючи зниженню запалення та покращенню кровообігу в тканинах
              Результати без лікарських засобів: допомогає досягти результатів без використання лікарських засобів, що дозволяє уникнути побічних ефектів та зменшити ризик алергічної реакції на препарати.
              Широкий спектр застосування: Лікування аплікатором D-ACTOR може застосовуватися для лікування різноманітних захворювань.
            <img
            style={{ maxHeight: '400px',maxWidth:'400px', padding: '1%' }}
            className="d-block w-100"
            src={vactor}
            alt="Кабінет"
            />
            <li>V-ACTOR</li>
            ДЛЯ ВІБРОТЕРАПІЇ®
            Використовується в екстримальних умовах, таких як хірургія, реабілітація та спортивна медицина. Він має такі переваги:
            Швидка регенерація тканин: Аплікатор V-ACTOR дозволяє прискорити процес регенерації тканин та зменшити час відновлення після травм або хірургічних втручань.
            Покращення кровообігу:може покращити кровообіг, що допомагає підвищити рівень кисню та харчових речовин, що отримує тканина.
            Зменшення болю: може зменшити біль, набряк та запалення, які часто супроводжуються із травмами та хірургічними втручаннями.
            Покращення функціональності: може покращити рухливість та функціональність тканин, сприяючи більш швидкому та ефективному відновленню.
            Сприяє відновленню лімф у місті проведення процедури
            <img
            style={{ maxHeight: '400px',maxWidth:'400px', padding: '1%' }}
            className="d-block w-100"
            src={vacuactor}
            alt="Кабінет"
          />
            <li>VACU-ACTOR®</li>
            Вакуумна терапія®
            Використовується в ударно-хвильовій терапії для покращення лімфодренажу та кровообігу в тканинах. Ось переваги використання VACU-ACTOR:
            Зняття гіперкератозу: допомогає видалити грубі, затверділі шари шкіри, такі як гіперкератоз, що може бути причиною болю та дискомфорту.
            VACU-ACTOR дуже гарно зарекомендував себе у лікуванні лімфостазу, целюліту та інших
            </ul>
        </Row>

        </Container>
    </Tab>
    <Tab eventKey="profile" title="MAGNETOLITH">
    <Container>
      <Row>
        <h2>MAGNETOLITH</h2>
        <Col sm={6}> {/* Увеличил размер для текстового блока */}
          <img
            style={{ maxHeight: '400px', padding: '1%' }}
            className="d-block w-100"
            src={magn}
            alt="Кабінет"
          />
        </Col>
        <Col sm={4}>
          <p>
          MAGNETOLITH — апарат, який проводить екстракорпоральну магнітно-трансдукціонну терапію (ЕМТТ®) для регенерації та реабілітації при захворюваннях кістково-м’язової системи.
          Показання до застосування системи MAGNETOLITH включають захворювання опорно-рухового апарату, 
          такі як: біль в спині, тендінопатіі ротаторної манжети або ахіллового сухожилля.
          Апарат MAGNETOLITH ефективно застосовується при захворюваннях опорно-рухового апарату, 
          таких як: дегенеративні захворювання суглобів, артрози, грижі міжхребцевого диска, спондилоартрози,
          хронічні болі, спортивні травми, та у ВІДНОВЛЕННІ ЛЕГЕНЕВОЇ ТКАНИНИ ПІСЛЯ ПЕРЕНЕСЕНОГО COVID-19
          </p>
        </Col>
        <h3>Основні переваги апарату MAGNETOLITH:</h3>
        <ol>
          <li>Неінвазивна амбулаторна терапія</li><br/>
          <li>Відсутність побічних ефектів</li><br/>
          <li>Широка область терапевтичного застосування</li><br/>
          <li>Висока частота коливань при великій глибині проникнення</li><br/>
        </ol>
        </Row>
        </Container>
    </Tab>
  </Tabs>
)
}