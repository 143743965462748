import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navibar from './Components/Navibar';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import { Home } from './Home';
import { About } from './About';
import { Service } from './Service';
import { Devices } from './Devices';
import { Contact } from './Contact';

function App() {
  return (
    <>
      <Router>
        <Navibar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Service" element={<Service />} />
          <Route path="/Devices" element={<Devices />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </Router>
      </>
  );
}

export default App;
