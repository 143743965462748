import React from "react";
import Footer from "./Components/Footer"
import DevicesGrid from "./Components/DevicesGrid";



export const Devices = () => (

<>
    <DevicesGrid/>    
    <Footer/>
    </>
);