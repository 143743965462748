    import React from "react";
    import Container from 'react-bootstrap/Container';
    import Accordion from 'react-bootstrap/Accordion';
    import Row from 'react-bootstrap/Row';
    import Col from 'react-bootstrap/Col';
    import Tab from 'react-bootstrap/Tab';
    import Tabs from 'react-bootstrap/Tabs';
    import logog from './images/2023.png';
    import aparatkosm11 from './images/aparatkosm11.jpg';
    import aparatkosm22 from './images/aparatkosm22.jpg';
    import ferment from './images/ferment.jpg';
    import massage from './images/massage.jpg';
    import karboksi from './images/karboksi.jpg';
    import piling from './images/piling.jpg';
    import Button from 'react-bootstrap/Button';
    import { useState } from 'react';
    import Modal1 from "./Modal1";
    import Modal2 from "./Modal2";
    import Modal3 from "./Modal3";
    import Modal4 from "./Modal4";
    import Modal5 from "./Modal5";
    import Modal6 from "./Modal6";
    import Modal7 from "./Modal7";
    import Modal8 from "./Modal8";
    import Modal9 from "./Modal9";
    import first1 from  './images/first1.jpg';
    import second2 from './images/second2.jpg';
    import third3 from  './images/third3.jpg';
    import electroporacia from  './images/electroporacia.jpg';
    import darson from './images/darson.jpg';
    import chistka from './images/chistka.jpg';
    import magnetolith from './images/magnetolith1.jpg';
    import oxyjet from './images/oxyjet.jpg';



    export default function ServiceGrid() {

        const [showModal1, setShowModal1] = useState(false);
        const [showModal2, setShowModal2] = useState(false);
        const [showModal3, setShowModal3] = useState(false);
        const [showModal4, setShowModal4] = useState(false);
        const [showModal5, setShowModal5] = useState(false);
        const [showModal6, setShowModal6] = useState(false);
        const [showModal7, setShowModal7] = useState(false);
        const [showModal8, setShowModal8] = useState(false);
        const [showModal9, setShowModal9] = useState(false);



        return(
            <Tabs
            defaultActiveKey="home"
            id="fill-tab-example"
            className="mb-3"
            fill
          >
                <Tab eventKey="home" title="Ударно-хвильова терапія">
                <Container>
              <Row>
                <Col sm={6}> {/* Увеличил размер для текстового блока */}
                  <img
                    style={{ maxHeight: '500px', padding: '1%' }}
                    className="d-block w-100"
                    src={logog}
                    alt="Кабінет"
                  />
                </Col>
                <Col sm={4}>
                  <p>
                  Ласкаво просимо до нашого розділу "Ударно-хвильова терапія", де ми пропонуємо передові технології для вашого здоров'я та благополуччя. 
                  Ударно-хвильова терапія (УХТ) – це ефективний метод лікування, який використовує звукові хвилі для стимуляції процесів в
                  організмі та поліпшення його функціональності.</p>
                </Col>
                <ul className="list-services border-solid border-0 border-l-4 border-main-color desktop:pl-1 mt-2 desktop:mt-1 mb-1-1/2 relative">
                <li><strong>Наші Послуги:</strong></li><br/>
<li><strong>Лікування болю:</strong> УХТ є ефективним засобом для зменшення болю в різних частинах тіла.
 Від хронічних болей до спортивних травм – ми пропонуємо індивідуальний підхід до кожного клієнта.</li>
<li><strong>Реабілітація та відновлення:</strong> Наші програми реабілітації допоможуть вам швидше відновитися після операцій, травм або інших медичних станів.
 УХТ сприяє активному зціленню тканин і поліпшує кровообіг.</li>
<li><strong>Лікування спортивних ушкоджень:</strong> Для спортсменів та активних осіб ми пропонуємо ефективні методи лікування спортивних травм за допомогою УХТ.
 Швидше повертайтеся до своїх улюблених занять без болю та обмежень.</li>

<li><strong>Напрямки в яких ми працюємо:</strong></li>
<li><strong>Ортопедія:</strong> Подагра, ахілодинія, плантарний фасціїт/п’яткова шпора, тибіальний стрес-синдром, тендиніт надколінника, тендінопатія в ділянці вертлюга, кальцифікуючі тендиніти, епіконделіт (латеральний/медіальний), стілоїдіт Де Кервена, контрактура Дюпіітрена, 
остеохондропатії, псевдоартрози, імпіджмент синдром, коксартроз 1-3 стадія, гонартроз 1-3 стадія, стимуляція зростання переломів та інші</li>
<li><strong>Неврологія:</strong> Мігрень (головний біль), міжхребцеві грижі та протрузії, міжреберна невралгія, лікування посттравматичних спазмів, для поліпшення рухливості, 
поліневропатія (PNP), неврит лицьового та трійчастого нерва, оперезуючий лишай, ударно-хвильова акупунктура, судоми литкових м’язів</li>
<li><strong>Урологія:</strong> Хвороба Пейроні (IPP), хронічний тазовий біль (CPPS), калькульозний та некалькульозний простатит, еректильна дисфункція (ED)</li>
<li><strong>Стоматологія:</strong> Периодонтит, пародонтоз, дисфункція скронево-нижньощелепного суглобу, укріплення ясен перед імплантацією</li><br/>
<li><strong>Комбустологія:</strong> Лікування опіків</li><br/>
<li><strong>Дерматологія:</strong> Післяопікові шрами, псоріаз, розацеа</li><br/>
<li><strong>Хірургія:</strong> Трофічні виразки внаслідок облітеруючого ендаартеріїта, розсмоктування післяопераційних рубців, зняття болю після пластики сіткою гриж</li>
                </ul>
                </Row>
                </Container>
            </Tab>
            <Tab eventKey="profile" title="Косметологія">
            <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
    >

      <Tab eventKey="home" id="estet" title="Естетична косметологія">
      <Container>
              <Row>
                <h2>Ферментотерапія</h2>
                <Col sm={6}> {/* Увеличил размер для текстового блока */}
                  <img
                    style={{ maxWight: '400px',maxHeight:'750px', padding: '1%' }}
                    className="d-block w-100"
                    src={ferment}
                    alt="Кабінет"
                  />
                </Col>
                <Col sm={4}>
                  <p>
                  Застосування косметологічних засобів на основі ферментів з оздоровчою та підтримуючою метою.
                  Комплексна щадна процедура дозволяє пацієнтам позбутися пігментних плям, акне/постакне, зменшити рубці та прибрати сліди мікроушкоджень.
                  </p>
                  <p>
                    Кому підходить ферментотерапія? Ферментні маски рекомендуються:<br/>
                    з появою перших ознак старіння – мімічних зморшок, в’ялості шкіри, зниження еластичності та втрати тонусу шкірних покривів, деформації овалу обличчя;<br/>
                    якщо шкіра виглядає тьмяною та втомленою;<br/>
                    при розширених порах і підвищеній жирності епідермісу;<br/>
                    якщо на обличчі під очима регулярно з’являються набряки, мішки, темні кола;<br/>
                    при схильності до висипання, акне;<br/>
                    якщо є ознаки розацеа, купероз;<br/>
                    утворилися молярні мішки – область під очима, де накопичується зайва жирова клітковина;<br/>
                    шкіра суха, схильна до лущення.</p>
                    <br/>
                    <p>Ферментотерапія забезпечує природне відновлення шкіри і суттєво покращує стан та зовнішній вигляд шкіри обличчя, шиї, зони декольте.
                Курс 4-10 процедур щонайменше  1 раз на тиждень</p>
                </Col>
                <p>Протипоказання до ферментотерапії – онкозахворювання, клаустрофобія.
                Є також обмеження – відносні чи тимчасові протипоказання:
                гострі дерматологічні захворювання (дерматит, екзема);
                декомпенсований цукровий діабет, гостра фаза інших хронічних захворювань;
                запальні захворювання, що спричинили підвищення температури тіла;
                рани, інфекційні запалення на шкірі в області проведення процедури.</p>
                </Row>
                <Row>
                <h2>Хіропластичний масаж (іспанський масаж обличчя)</h2>
                <Col sm={6}> {/* Увеличил размер для текстового блока */}
                  <img
                    style={{ maxWight: '400px',maxHeight:'650px', padding: '1%' }}
                    className="d-block w-100"
                    src={massage}
                    alt="Кабінет"
                  />
                </Col>
                <Col sm={4}>
                  <p>
                Хіропластичний масаж або (іспанський масаж обличчя) – це унікальна техніка мануальної терапії,
                яка впливає на стан м’язів, кровоносну та лімфатичну системи людини і покращує колір шкіри  обличчя, загальний стан організму та проводить помітний ліфтинг після кожної процедури.
                Проведення іспанського масажу або хіромасаж допоможе підвищити пружність шкіри, зменшити набряки та покращує контур  підборіддя.
                Відмінність хіропластичного масажу обличчя  від інших масажних технік полягає:
                • специфіка масажних ліній;
                • пластичність рухів направлене на покращення відтоку  лімфи;
                • індивідуальний підход і врахування особливостей кожного організму.
                Іспанський масаж
                Іспанський масаж обличчя також його називають  хіромасаж- визнана унікальною і вважається мануальною альтернативою інєкційній естетичній медицині..
                Техніка іспанський хіромасаж рекомендується при:
                • “змученій” шкірі обличчя
                • схильності до набряків та мішків під очима;
                • нечіткому контуру підборіддя,скул,
                нижньої третини обличчя;
                • дрібна сітка зморшок;
                • суха зневоднена шкіра обличчя
                  </p>
                </Col>
                <p>
                  Результат від техніки  хіромасажу обличчя помітний одразу після першого сеансу. Для того щоб продовжити і закріпити ефект, 
                  необхідно пройти курс масажів від 5 до 15 залежить від віку та стану шкіри обличчя.
                    Ця іспанська масажна техніка чудова альтернатива радикальним ін’єкційним процедурам. 
                    Якщо вигляд та стан шкіри вас не задовільняє, а до  кардинальних рішень з тих чи інших причин не готові, 
                    курс хіромасажу для обличчя, саме те що потрібно для корекції та підтримки доглянутого вигляду.
                    Після проведення сеансів курсу іспанського масажу досягаємо результату:
                    • корекція дрібної сітки зморшок;
                    • покращення лінії підборіддя та скул;
                    • покращення клітинного живлення;
                    • стимуляція клітин шкіри обличчя та зони декольте;
                    • покращення загального стану шкіри (зволоження та живлення шкіри);
                    • стимуляція колагенових волокон, як наслідок еластична шкіра;
                    • зменшення набряку обличчя;
                    • уповільнюється процес старіння шкіри;
                    Кількість процедур в одному курсі від 5 до 15 з періодичністю через день або  два рази в тиждень. 
                    Кількість визначається враховуючи вік та стан шкіри обличчя, підбирається індивідуально. Курс на рік: один або два,між ними можна проходити підтримуючі сеанси.
                    </p>
                </Row>

                <Row>
                <h2>Карбоксітерапія</h2>
                <Col sm={6}> {/* Увеличил размер для текстового блока */}
                  <img
                    style={{ maxWight: '400px',maxHeight:'650px', padding: '1%' }}
                    className="d-block w-100"
                    src={karboksi}
                    alt="Кабінет"
                  />
                </Col>
                <Col sm={4}>
                  <p>
                Процедура карбокситерапії полягає у впливі на шкіру молекулярного вуглекислого газу СО2 та інших активних компонентів.
                Поживні речовини проникають глибоко у клітини та запускають процес відновлення та регенерації шкіри. Косметологи дозволяють 
                використовувати карбокситерапію для дівчат з 20-річного віку. Допомагає вирівняти колір обличчя, прибрати синці і «мішки» під очима.
                Сприяє лікуванню слідів акне і постакне. Регулює діяльність сальних залоз, знижує жирність шкіри. Бореться із судинними змінами. 
                Показання для проведення карбоксітерапії обличчя
                • Погіршення стану шкіри,розширені пори,купероз
                • Дрібні зморшки,мімічні зморшки
                • Зневоднення шкіри.
                • Профілактика вікових змін шкірного покриву.
                • Наявність акне,постакне
                • Поява пігментації.
                • Набряклість на обличчі.
                • Поява темних кіл під очима.
                Курс:5-10 процедур 1-2 рази на тиждень
                Протипокази :
                .вагітність,лактація
                .наявність запальних елементів,герпесу 
                .нещодавно перенесений інфаркт,інсульт
                .артеріальна гіпертензія
                .індивідуальна чутливість до компонентів препарату
                Фото треба відредагувати,щоб не було підписів
                  </p>
                </Col>
                </Row>

                <Row>
                <h2>Хімічні пілінги</h2>
                <Col sm={6}> {/* Увеличил размер для текстового блока */}
                  <img
                    style={{ maxWight: '400px',maxHeight:'650px', padding: '1%' }}
                    className="d-block w-100"
                    src={piling}
                    alt="Кабінет"
                  />
                </Col>
                <Col sm={4}>
                <p>
                  процес рівномірного відлущування та видалення поверхневих шарів відмерлих клітин після впливу розчинів слабких кислот. 
                  Результатом процедур стає активізація вироблення колагену та еластину, стимулювання оновлення клітин епідерму. 
                  Спостерігається помітний ефект, що омолоджує, збільшення тонусу шкіри обличчя, її тонусу.
                  Хімічний пілінг має комплексний ефект, покращує естетику та фізіологію, надає лікувальну дію. Це ефективний 
                  спосіб вирівнювання шкіри, позбавлення від дрібних шрамів та постакне, застійних плям.
                  Процедура може проводитись у будь-якому віці для всіх типів шкіри. Важливо врахувати протипоказання та правильно підібрати концентрацію препаратів.
                </p>
                <p>До загальних показань косметологічної процедури відносяться:
                    проблемна шкіра із різними типами захворювань;
                    утворення рубців, вросле волосся;
                    потовщення та нерівності рогового шару;
                    тьмяний відтінок, порушення пігментації;
                    слабка еластичність, млявість, надмірна сухість, в’ялість;
                    надмірно жирна шкіра;
                    фотостаріння;
                    вікові зміни (одутлість, обвисання, зморшки та ін.).
                </p>
                </Col>
                <h2> Види хімічних пілінгів </h2>
                <Col sm={8}>
                <Button  className="buttonauth" onClick={setShowModal1}style={{ marginRight: '10px', marginBottom: '10px' }}> Макропіл</Button>
                <Button  className="buttonauth" onClick={setShowModal2}style={{ marginRight: '10px', marginBottom: '10px' }}> PRX-T33</Button>
                <Button  className="buttonauth" onClick={setShowModal3}style={{ marginRight: '10px', marginBottom: '10px' }}> MCA-35</Button>
                <Button  className="buttonauth" onClick={setShowModal4}style={{ marginRight: '10px', marginBottom: '10px'}}>  APPeex</Button>
                <Button  className="buttonauth" onClick={setShowModal5}style={{ marginRight: '10px', marginBottom: '10px' }}> Kemikum</Button>
                <Button  className="buttonauth" onClick={setShowModal6}style={{ marginRight: '10px', marginBottom: '10px' }}> Мигдалевий з ДмАЄ</Button>

                
                </Col>
                <p>Зовні пацієнт виглядає молодшим через вирівнювання та відновлення кольору шкіри, появи ефекту «сяйва» та пружності.
                        Внутрішньо організм отримує стимул для природного поновлення. 
                        З’являються нові клітини та судини, активізуються обмінні процеси.
                        Поєднання внутрішніх та зовнішніх сприятливих впливів зберігається надовго.</p>
                </Row>
                <Modal1 show={showModal1} onHide={() => setShowModal1(false)} />
                <Modal2 show={showModal2} onHide={() => setShowModal2(false)} />
                <Modal3 show={showModal3} onHide={() => setShowModal3(false)} />
                <Modal4 show={showModal4} onHide={() => setShowModal4(false)} />
                <Modal5 show={showModal5} onHide={() => setShowModal5(false)} />
                <Modal6 show={showModal6} onHide={() => setShowModal6(false)} />


                <Row>
                <h2>Гігієнічна чистка</h2>
                <Col sm={6}> {/* Увеличил размер для текстового блока */}
                  <img
                    style={{ maxWight: '400px',maxHeight:'750px', padding: '1%' }}
                    className="d-block w-100"
                    src={chistka}
                    alt="Кабінет"
                  />
                </Col>
                <Col sm={4}>
                  <p>
                  ДЛЯ ЧОГО ПОТРІБНА ПРОФЕСІЙНА ЧИСТКА ОБЛИЧЧЯ?
                    Щоденне очищення шкіри за допомогою пінки, тоніка або іншого засобу для обличчя дозволяє зняти косметику та поверхневе забруднення. 
                    При цьому, як правило, не видаляються ороговілі клітини і не очищаються пори. Особливо актуальна ця проблема для власників жирної, проблемної або чутливої шкіри.
                    Поступово її пори забиваються, що призводить до появи акне, запалень і гнійних висипань.
                    Також без глибокого очищення в шкірі сповільнюються обмінні процеси та регенерація, через що вона швидше втрачає свою пружність та еластичність.
                  </p>
                  <p>
                    Правильно підібрана та проведена чистка обличчя забезпечує:
                    глибоке очищення шкіри;
                    звуження пор;
                    усунення або зменшення акне та інших висипань;
                    вирівнювання рельєфу та кольору шкіри;
                    покращення обмінних процесів та регенерацію тканин;
                    підвищення пружності шкіри;
                    скорочення кількості дрібних зморшок;
                    профілактику старіння шкіри
                    </p>
                </Col>
                <p>Протипоказання до ферментотерапії – онкозахворювання, клаустрофобія.
                Є також обмеження – відносні чи тимчасові протипоказання:
                гострі дерматологічні захворювання (дерматит, екзема);
                декомпенсований цукровий діабет, гостра фаза інших хронічних захворювань;
                запальні захворювання, що спричинили підвищення температури тіла;
                рани, інфекційні запалення на шкірі в області проведення процедури.</p>
                <Button className="buttonauth" onClick={setShowModal7}style={{ marginRight: '10px', marginBottom: '10px' }}>МЕХАНІЧНА ЧИСТКА ОБЛИЧЧЯ</Button>
                <Button className="buttonauth" onClick={setShowModal8}style={{ marginRight: '10px', marginBottom: '10px' }}>УЛЬТРАЗВУКОВА ЧИСТКА ОБЛИЧЧЯ</Button>
                <Button className="buttonauth" onClick={setShowModal9}style={{ marginRight: '10px', marginBottom: '10px' }}>ВІДМІННОСТІ ПРОФЕСІЙНОЇ ЧИСТКИ ОБЛИЧЧЯ ТА ЧИСТКИ В ДОМАШНІХ УМОВАХ</Button>

                </Row>
                <Modal7 show={showModal7} onHide={() => setShowModal7(false)} />
                <Modal8 show={showModal8} onHide={() => setShowModal8(false)} />
                <Modal9 show={showModal9} onHide={() => setShowModal9(false)} />
                </Container>
      </Tab>





      <Tab eventKey="profile" id="aparat" title="Апаратна косметологія">
      <Container>
              <Row>
                <h2>Мікрострумова терапія</h2>
                <Col sm={6}> {/* Увеличил размер для текстового блока */}
                  <img
                    style={{ maxWight: '400px',maxHeight:'750px', padding: '1%' }}
                    className="d-block w-100"
                    src={aparatkosm11}
                    alt="Кабінет"
                  />
                </Col>
                <Col sm={4}>
                  <p>
                  Мікроструми - це затребувана сучасна технологія, яка використовує дуже низький рівень струму, відповідного електричного потенціалу людського тіла.
                  Мікроструми Bio-Ultimate Platinum працюють як зовнішнє джерело енергії, який організм може використовувати для швидкого відновлення і сяйва шкіри.
                  Клінічні дослідження показують негайний результат при використанні мікрострумів з параметрами менше ніж 400 мкА.
                  Метод мікрострумової терапії заснований на впливі фізіологічних мікрострумів на різні шари шкіри і м'язову систему. Результатом впливу є нормалізація фізіологічної активності клітин шкіри, м'язової тканини, лімфатичних вузлів і судин.
                  Мікрострумова терапія призначена для профілактики і лікування будь-якого типу старіння шкіри, корекції деформаційного старіння 
                  за рахунок довгострокового ліфтингу лицьових м'язів, пролонгованого дренажного ефекту.</p>
  </Col>
  <Col sm={5}>
    <ul>
<li>Обличчя:</li><br/>
  <li>• Корекція овалу обличчя</li> <br/>
  <li>• Лікування сухої, жирної і в'янучої шкіри, акне, гіперпігментації за допомогою впровадження спеціальних масок</li> <br/>
  <li>• Стимуляція після дермабразії</li> <br/>
  <li>• Реабілітація після пластичних операцій</li> <br/>
  <li>• Іонофорез</li> <br/>
  <li>• Лімфодренаж</li><br/>
  <li>• Міоліфтинг обличчя</li> <br/>
  <li>• Швидкий ліфтинг - експрес-процедура</li><br/>
  <li>• Усунення мімічних зморщок</li><br/>
 </ul>
 <ul>
  <li>ВОЛОСЯНА ЧАСТИНА ГОЛОВИ:</li><br/>
<li> •   Відновлення шкіри голови</li><br/>
<li> •   Активація росту волосся, зміцнення,профілактика випадіння,зменшення жирності шкіри голови</li><br/>
<li>Самий популярний американський апарат мікрострумовоі терапіі Bio-Ultimate PLATINUM</li><br/>
</ul></Col>
<Col sm={6}>
<img
                    style={{ maxWight: '400px',maxHeight:'750px', padding: '1%' }}
                    className="d-block w-100"
                    src={aparatkosm22}
                    alt="Кабінет"
                  />
</Col>
                  <p>Професійна космецевтика Bio-Therapeutic (США)
                  Спеціально розроблена компанією Bio-Therapeutic під апаратні методики. Професійні засоби <a href="https://alfaspa.ua/cosmetics/bio-therapeutic/">
                  <strong>AflaSpa</strong> </a>
                  для кожного етапу процедур: базова лінія для очищення, підготовки до мікрострумової терапії та післяпроцедурний догляд.
                  Platinum лінія з пептидами - цілеспрямовані засоби для вирішення різних естетичних проблем шкіри. За допомогою апарату Bio-Ultimate Platinum можна впровадити сироватку 
                  <a href="https://alfaspa.ua/cosmetics/serums/"><strong> (Посилання на крем)</strong></a> крем для більш ефективного та швидкого результату.</p>
                  <ul>
                  <li>Пластична хірургія та інвазивні процедури:</li><br/>
                  <li> •   Передопераційна підготовка і постопераційний супровід пацієнтів</li><br/>
                  <li> •   Підготовка до лазерного шліфування і супровід пацієнта після нього</li><br/>
                  <li> •   Післяопераційні набряки, лімфостаз</li><br/>
                  <li> •   Обробка рубців</li><br/>
                  <li> •   Пересадка волосся</li><br/>
                  <li> •   Супровід клієнтів після ліпосакції</li><br/>
                  Курс складає 10-12 процедур 2-3 рази на тиждень,ефект зберігається до 6 місяців Bio-Ultimate Platinum має медичні сертифікати FDA, ISO та СЕ
                  </ul>
                </Row>
                <Row>
                <h2>Безінєкційна  киснева мезотерапія OXY JET</h2>
                <Col sm={6}> {/* Увеличил размер для текстового блока */}
                  <img
                    style={{ maxWight: '400px',maxHeight:'650px', padding: '1%' }}
                    className="d-block w-100"
                    src={oxyjet}
                    alt="Кабінет"
                  />
                </Col>
                <Col sm={4}>
                  <p>
                  T-Accent AIR - сучасна, високоефективна і безболісна терапія, що дозволяє доставляти активні компоненти в проблемну зону за 
                  допомогою чистого кисню без травмування тканин. Завдяки такому впливу апаратна киснева терапія ще називається оксимезотерапією або безголковою мезотерапією.
                Оксимезотерапія BT-Accent AIR - ідеальна процедура для корекції вікових змін шкіри, боротьби з сухістю, акне і гіперпігментацією.
                Американський апарат BT-Accent AIR - безболісна, релаксуючая методика омолодження, яка:
                • Впроваджує в глибокі шари шкіри високоактивні інгредієнти
                •   Підсилює дію високоактивних речовин в епідермісі
                •   Насичує епідерміс киснем
                </p>
                </Col>
                <Col sm={12}>
                <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Три етапи процедури допоможуть досягти вражаючого ефекту вже після першого сеансу:</Accordion.Header>
        <Accordion.Body>
                1. Подача кисню і сироватки в глибокі шари шкіри за допомогою маніпули-пістолета Wand
                На всю поверхню обличчя і шиї дуже дрібнодисперсно розпорошується активний засіб. Уже в перші хвилини впливу 
                помітно зменшення запалення, шкіра стає рівною і набуває красивого кольору. Струмені кисню прискорюють процес абсорбції косметичних препаратів,
                тонізують шкіру, виробляють синтез колагену і стимулюють клітинний обмін.<br/>
                <Col sm={6}> {/* Увеличил размер для текстового блока */}
                  <img
                    style={{ maxWight: '400px',maxHeight:'650px', padding: '1%' }}
                    className="d-block w-100"
                    src={first1}
                    alt="Кабінет"
                  />
                </Col>
                2. Розгладження зморщок та інтенсивне впровадження інгредієнтів з киснем - маніпулою Jet
                Окремі молекули кисню ще більше проникають у глибокі шари шкіри і "проносять" із собою молекули інших речовин, наприклад,
                низькомолекулярну гіалуронову кислоту, фітопрепарати, вітаміни тощо. Шкіра наповнюється зсередини, дрібні зморшки розгладжуються,
                а глибокі стають менш помітними. Як доповнення клієнт, отримуючи делікатний масаж обличчя, готується до наступного етапу релаксу.<br/>
                <Col sm={6}> {/* Увеличил размер для текстового блока */}
                  <img
                    style={{ maxWight: '400px',maxHeight:'650px', padding: '1%' }}
                    className="d-block w-100"
                    src={second2}
                    alt="Кабінет"
                  />
                </Col>
                3. Повне насичення киснем шкіри і всього організму загалом, за допомогою маски Dome
                Апарат BT-Accent AIR подає на обличчя тонкий потік чистого кисню, клієнт дихає і повністю розслабляється.
                Кисень, будучи одним з найважливіших елементів, забезпечує процеси клітинної регенерації, тканинного дихання, кровообіг. 
                Він також має антисептичну дію і перешкоджає росту бактерій. Відбувається комплексне відновлення й омолодження організму.
                <Col sm={6}> {/* Увеличил размер для текстового блока */}
                  <img
                    style={{ maxWight: '400px',maxHeight:'650px', padding: '1%' }}
                    className="d-block w-100"
                    src={third3}
                    alt="Кабінет"
                  />
                </Col>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
                  </Col>
                  <Col sm={12}>
                  <Accordion>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Переваги кисневої терапії BT-Accent AIR</Accordion.Header>
        <Accordion.Body>
        Передові технології від Bio-Therapeutic поєднують у собі найсучасніші інгредієнти, забезпечуючи потужне зволоження та оновлену сяючу шкіру для Ваших клієнтів.<br/>
        •   Оксимезотерапія BT-Accent AIR - одна з найприємніших апаратних процедур, без голок і без болю. Процедура абсолютно безболісна і виконує великий спектр завдань з омолодження.<br/>
        •   Миттєвий видимий результат.<br/>
        •   Процедуру можна проводити в будь-який період року, зокрема і в період інсоляції.<br/>
        •   Без протипоказань, показана навіть вагітним і жінкам, що годують груддю.<br/>
        •   Киснева насадка Jet дозволяє провести впровадження активних речовин максимально близько до очей, на губах і точково пропрацювати неглибокі зморшки.<br/>
        •   Завдяки застосуванню кисневої маски Dome терапевтичний вплив кисню відбувається не тільки зовні, але і збагачує організм клієнта зсередини. Це унікальна можливість розслабитися, подихати насиченим киснем в умовах галасливого міста<br/>
        •   Процедура BT-Accent AIR прирівнюється до годинної пішої прогулянки в Альпах<br/>
        •   Відсутність обмежень за віком і типом шкіри: показано навіть для найчутливіших пацієнтів, незалежно від фототипу шкіри<br/>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <Accordion>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Оксигенотерапія BT-Accent AIR забезпечує чудові результати після першої ж процедури</Accordion.Header>
        <Accordion.Body>
        •   Свіжий, сяючий рівний колір шкіри<br/>
        •   Потужне зволоження та оновлення<br/>
        •   Очищення шкіри<br/>
        •   Зменшення дрібних зморщок<br/>
        •   Зміцнення і поліпшення еластичності<br/>
        •   Миттєва тонізація, надання пружності шкіри<br/>
        •   Вирівнюється рельєф, звужуються пори<br/>
        •   Усувається почервоніння, подразнення<br/>
        •   Знижується набряклість шкіри<br/>
        •   Зменшуються кола під очима<br/>
        •   Прискорюються процеси регенерації<br/>
        • Після кожної процедури оксимезотерапії Bt-Accent AIR, клієнт має сяючий колір обличчя, зволожену, відпочилу шкіру, що випромінює здоров'я.<br/>
        BT-Accent AIR має медичні сертифікати FDA, ISO та СЕ<br/>
        Курс складає 5-10 процедур 1-2 рази на тиждень
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
                    </Col>
                </Row>

                <Row>
                <h2>Електропорація (гідромезотерапія) - апаратна безін'єкційна мезотерапія</h2>
                <Col sm={6}> {/* Увеличил размер для текстового блока */}
                  <img
                    style={{ maxWight: '400px',maxHeight:'650px', padding: '1%' }}
                    className="d-block w-100"
                    src={electroporacia}
                    alt="Кабінет"
                  />
                </Col>
                <Col sm={4}>
                  <p>Електропорація - дуже ефективний і безболісний метод, в ході якого біоактивні косметичні та лікарські препарати вводяться в глибокі 
                  шари шкіри обличчя і тіла.
                  Це сучасна і ефективна косметична процедура, при якій лікувальні препарати вводяться не за допомогою голки, а опосередковано електричними імпульсами.
                  Електричний струм є провідником, що приносить активні речовини в глибокі шари дерми. При цьому шкірні покриви не травмуються:
                  не буде проколів, синців, набряків,
                  відсутній ризик інфекції і запалення.
                  Після електропорації шкіра стає зволоженою, пружною, підтягнутою, вирівнюється колір обличчя, зменшується кількість і глибина зморшок, 
                  овал облічча набуває чіткий контур.
                  Електропорація є процедурою вибору при наявності протипоказань для ін'єкційної мезотерапії, наприклад, в відновлювальний період після лазерних процедур, микродермобразии, 
                  хімічних пілінгів або при проблемах зі згортанням крові. 
                  Також електропорація застосовується при підготовці до пластичних операцій на шкірі обличчя і тіла.
                  </p>
                </Col>
                <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Показання до проведення електропорації:</Accordion.Header>
        <Accordion.Body>
        Вікові зміни і в'яла шкіра<br/>
        Сухість і дряблість шкіри, зневоднення<br/>
        Нерівний колір обличчя<br/>
        Гіперпігментація<br/>
        Профілактика і усунення зморшок<br/>
        Розширені пори і прищі<br/>
        Акне і постакне<br/>
        Розацеа (купероз)<br/>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>

      <h2>Дарсонвалізація обличчя,тіла,волосистої частини голови.</h2>
                <Col sm={6}> {/* Увеличил размер для текстового блока */}
                  <img
                    style={{ maxWight: '400px',maxHeight:'650px', padding: '1%' }}
                    className="d-block w-100"
                    src={darson}
                    alt="Кабінет"
                  />
                </Col>
                <Col sm={4}>
                  <p>Дарсонвалізація – один із видів електростимуляції, належить до фізіотерапевтичних процедур для лікування деяких неврологічних,
                    судинних, кісткових, шкірних та інших захворювань за допомогою апарату дарсонваль. Цей метод терапії передбачає дію на тканини
                    короткочасними імпульсами перемінного струму високої частоти та напруги, але малої сили. При цьому виникають і терапевтично 
                    впливають незначне тепло, інфрачервоні промені, низькочастотний ультразвук та озон. 
                    Дарсонвалізація завжди є допоміжною терапією, її дія доповнює та  багаторазово підсилює ефективність дії медичних кремів та мазей.
                  </p>
                </Col>
                <Accordion>
  <Accordion.Item eventKey="0">
    <Accordion.Header>Показання до застосування дарсонвалю</Accordion.Header>
    <Accordion.Body>
      <Row>
        <Col sm={6}>
         <strong>В дерматології:</strong> <br />
          лікування екземи<br />
          лишаїв<br />
          вугрів<br />
          нейродерміту<br />
          псоріазу<br />
          червоного плаского лишаю<br />
          бородавок<br />
          осередкової та системної склеродермії<br />
          келоїдів<br />
          себореї<br />
          лупи<br />
          облисіння<br />
        </Col>
        <Col sm={6}>
         <strong>Запальні процеси та травматичні ушкодження шкіри:</strong> <br />
          фурункули<br />
          забої<br />
          гематоми<br />
          інфільтрати та рани<br />
          трофічні виразки<br />
          обмороження<br />
          опіки<br />
        </Col>
      </Row>
    </Accordion.Body>
  </Accordion.Item>
</Accordion>
</Row>
</Container>
</Tab>
</Tabs>
</Tab>
            <Tab eventKey="longer-tab" title="Екстракорпоральна магнітно-трансдукціонна терапія ЕМТТ®">
            <h3 className="text-center">Екстракорпоральна магнітно-трансдукціонна терапія ЕМТТ® </h3> 
            <Container>
              <Row>
                <Col sm={6}> {/* Увеличил размер для текстового блока */}
                  <img
                    style={{ maxHeight: '500px', padding: '1%' }}
                    className="d-block w-100"
                    src={magnetolith}
                    alt="Кабінет"
                  />
                </Col>
                <Col sm={4}>
                  <p>
                  Терапія яка спрямована на регенерацію та реабілітацію при захворюваннях кістково-м'язової системи. Система MAGNETOLITH
                  використовується для лікування різноманітних захворювань опорно-рухового апарату, зокрема таких, 
                  як біль в спині, тендінопатії ротаторної манжети та ахіллового сухожилля.
                  Ефективно застосовується при захворюваннях опорно-рухового апарату, таких як: дегенеративні захворювання суглобів, артрози, 
                  грижі міжхребцевого диска, спондилоартрози, хронічні болі, спортивні травми, та у ВІДНОВЛЕННІ ЛЕГЕНЕВОЇ ТКАНИНИ ПІСЛЯ ПЕРЕНЕСЕНОГО COVID-19</p>
                </Col>
                </Row>
                </Container>
            </Tab>
          </Tabs>


    );
    }
