import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Magn1 from './images/magn1.jpg';
import Magn2 from './images/magn2.jpg';
import Magn3 from './images/magn3.jpg';
import Slider from './Slider';
import EstetLogo from './images/estetkosm.jpg'
import AparatKosm from './images/aparatkosm.jpg'

export default function HomeGrid() {
  return (
    <Container>
      <Row>
        <Col>
        <Slider/>
        </Col>
        <Col sm={4}>
        <h1>Ударно-хвильова терапія</h1>
        Ударно-хвильова терапія (УХТ) є неінвазивною методикою лікування, яка використовує звукові хвилі високої інтенсивності для лікування різних медичних патологогічних станів. 
        Вона широко застосовується в фізіотерапії і спортивній медицині.
        Процедура УХТ полягає в тому, що пацієнт піддається серії коротких, інтенсивних звукових хвиль, які передаються через шкіру до тканин в організмі. 
        Ці ударні хвилі генеруються спеціальними пристроями які вимагають кваліфікованого медичного персоналу для їх проведення, а саме такі спеціалісти працюють у нашому медичному центрі.
            </Col>
      </Row>
      <h2 className="text-center" style={{padding:"20px"}} > Екстракорпоральна магнітно-трансдукціонна терапія (ЕМТТ®) </h2>
      <Row className="justify-content-center">
      <Col xs={9} sm={6} md={4} className='mb-4'>
        <Card style={{ width: '18rem', padding: '1%' }}>
          <Card.Img variant="top" src={Magn1} style={{ height: '200px', objectFit: 'cover' }} />
          <Card.Body>
            <Card.Title>Неінвазивна амбулаторна терапія</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={9} sm={6} md={4} className='mb-4'>
        <Card style={{ width: '18rem', padding: '1%' }}>
          <Card.Img variant="top" src={Magn2} style={{ height: '200px', objectFit: 'cover' }} />
          <Card.Body>
            <Card.Title>Відсутність побічних ефектів</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={9} sm={6} md={4} className='mb-4'>
        <Card style={{ width: '18rem', padding: '1%' }}>
          <Card.Img variant="top" src={Magn3} style={{ height: '200px', objectFit: 'cover' }} />
          <Card.Body>
            <Card.Title>Широка область терапевтичного застосування</Card.Title>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <h2 className="text-center" style={{padding:"20px"}}>Косметологія</h2>
    <Row className="justify-content-center">
      <Col xs={9} sm={6} md={4} className='mb-4'>
        <Card style={{ width: '18rem', padding: '1%' }}>
          <Card.Img variant="top" src={EstetLogo} style={{ height: '200px', objectFit: 'cover' }} />
          <Card.Body>
            <Card.Title>Естетична косметологія</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={9} sm={6} md={4} className='mb-4'>
        <Card style={{ width: '18rem', padding: '1%' }}>
          <Card.Img variant="top" src={AparatKosm} style={{ height: '200px', objectFit: 'cover' }} />
          <Card.Body>
            <Card.Title>Апаратна косметологія</Card.Title>
          </Card.Body>
        </Card>
      </Col>
    </Row>
      <h2 className="text-muted text-center">Часті запитання</h2>
      <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Що таке ударно-хвильова терапія і в яких сферах вона використовується в медицині?</Accordion.Header>
        <Accordion.Body>
        <strong>Ударно-хвильова терапія (УХТ)</strong> - це неінвазивний медичний метод, використовуваний для лікування різноманітних захворювань, зокрема травм м'язів і суглобів,
              запалення сухожилля, каменів в нирках, та інших станів.
              Вона базується на передачі ударних хвиль через шкіру в уражену ділянку тіла, 
              сприяючи зниженню болю, покращенню кровообігу та зміцненню тканин.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Чи шкідлива ударно-хвильова терапія?</Accordion.Header>
        <Accordion.Body>
       <strong>Користь УХТ</strong> полягає в можливості отримати позитивний результат за короткий час, сеанс проводиться один раз на тиждень, кількість сеансів 5-7-10 в залежності від захворювання
        без травмування м’яких тканин, як при оперативному лікуванні.
        Однак при вагітності, та дітям у місцях зони росту кісток таке лікування УХТ не проводиться.<br/>
        <strong>Не проводиться УХТ:</strong><br/>
        <li>при гнійних процесах в пошкодженій ділянці тіла або кінцівки</li><br/>
        <li>в місцях де було введено глюкокортикостероїди (треба витримати 4-6 тижнів, потім можна)</li><br/>
        <li>при онкологічних захворюваннях</li><br/>
        <li>хворим з гемофілією (порушення згортання крові)</li><br/>
        <li>при наявності у пацієнта штучного водію ритму (кардіостимулятора)</li><br/>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Які фактори можуть впливати на ефективність ударно-хвильової терапії та як можна максимізувати її результативність?</Accordion.Header>
        <Accordion.Body>
        Ефективність залежить від <strong>типу травми</strong>, <strong>індивідуальних особливостей пацієнта</strong> та правильного вибору параметрів обладнання.
        Співпраця з кваліфікованим фахівцем та дотримання рекомендацій підвищить результативність терапії.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Чи існує можливість використання ударно-хвильової терапії для попередження травм та підтримання оптимального стану опорно-рухового апарату?</Accordion.Header>
        <Accordion.Body>
        <strong>Так</strong>, ударно-хвильова терапія може використовуватися як <strong>профілактичний захід для зміцнення тканин</strong>, <strong>покращення кровообігу</strong> та <strong>запобігання можливим травмам</strong> опорно-рухового апарату.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Чим відрізняється Екстракорпоральна магнітно-трансдукціонна терапія (ЕМТТ®) від інших методів лікування травм і захворювань опорно-рухового апарату?</Accordion.Header>
        <Accordion.Body>
        ЕМТТ® базується на <strong>використанні магнітних полів для стимуляції клітин і тканин</strong>, що відрізняє його від інших методів.
        Цей підхід сприяє активації внутрішніх регенеративних процесів без необхідності хірургічних втручань.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Які показання та протипоказання існують для Екстракорпоральної магнітно-трансдукціонної терапії, і для кого цей метод може бути особливо корисним?</Accordion.Header>
        <Accordion.Body>
        Показання включають <strong>різноманітні травми, хронічні захворювання опорно-рухового апарату та м'язово-суглобові розлади.</strong> <strong>Протипоказання</strong>
         можуть включати вагітність, серцеві проблеми.<br/>
        ЕМТТ® може бути особливо корисною для пацієнтів, які не можуть або не хочуть використовувати інші методи лікування.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>Який зазвичай період лікування Екстракорпоральною магнітно-трансдукціонною терапією, та які результати можна очікувати?</Accordion.Header>
        <Accordion.Body>
        Тривалість лікування може варіюватися від<strong> кількох тижнів до кількох місяців, залежно від характеру проблеми</strong>.
      <strong> Зазвичай</strong> пацієнти помічають покращення в болі та функціональності <strong> після перших сеансів</strong>, але повноцінні результати можуть виявитися пізніше у процесі лікування.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>    
    </Container>
  );
}