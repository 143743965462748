import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import { useState } from "react";
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal.css';
import prx from './images/prx.jpg';
import prx1 from './images/prx1.jpg';


export default function Modal1(props) {

  const [selectedNavItem, setSelectedNavItem] = useState(null);

  const handleNavItemClick = (eventKey) => {
    setSelectedNavItem(eventKey === selectedNavItem ? null : eventKey);
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        PRX-T33 пілінг
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Col sm={12}>
  <p>Без перебільшення, революційна методика омолодження шкіри без уколів і тривалого періоду реабілітації. 
  Виробник цього хімічного пілінгу — італійська косметична компанія WiQo med.
  Абсолютно новий засіб для серединного очищення шкіри обличчя, завдяки унікальній формулі миттєво проникає в глибокі шари дерми, 
  запускаючи активний процес регенерації, при цьому абсолютно не ушкоджує верхній шар шкірного покриву, епідерміс. 
  Після процедури не з'являється почервоніння, немає ознак лущення шкіри, а значить, ви не випадаєте зі свого звичного ритму життя після процедури.
  Підходить для всіх типів шкіри 18+</p>
</Col>
<Col sm={6}>
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div style={{ maxWidth: '200px', maxHeight: '325px', padding: '1%' }}>
      <img
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        src={prx}
        alt="Кабінет"
      />
    </div>
    <div style={{ maxWidth: '200px', maxHeight: '325px', padding: '1%' }}>
      <img
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        src={prx1}
        alt="Кабінет"
      />
    </div>
  </div>
</Col>

  <Col>
      <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Переваги</Accordion.Header>
        <Accordion.Body>
        <li>Можна робити в будь-який час року, не дивлячись на температурні перепади або зміну сезонів</li><br/>
        <li>Процедура практично безболісна (деякий дискомфорт присутній, але з відчуттями від ін'єкцій не порівняти)</li><br/>
        <li>Мала тривалість процедури (20 хвилин разом з підготовкою)</li><br/>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Протипоказання до застосування</Accordion.Header>
        <Accordion.Body>
      <li>Інфекційні захворювання</li><br/>
      <li>Висока чутливість шкіри</li><br/>
      <li>Непереносимість або алергія на компоненти</li><br/>
      <li>Вагітність і період грудного вигодовування</li><br/>
      <li>Відкриті рани, порізи, садна</li> <br/>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Показання до застосування</Accordion.Header>
        <Accordion.Body>
      <li>Акне</li> <br/>
      <li>Зморшки на лобі</li> <br/>
      <li>Зморшки навколо очей</li><br/>
      <li>Носогубні складки</li><br/>
      <li>Пігментація</li><br/>
      <li>В'яла або шкіра яка втратила пружність</li><br/>
      <li>Червоні плями після вугрового висипу</li><br/>
      <li>Атрофічні рубці</li><br/>
      <li>Постакне</li><br/>
      <li>Розтяжки</li><br/>
      <li>Вікова пігментація</li><br/>
      <li>Профілактика фотостаріння шкіри</li> 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </Col>
       </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Закрити</Button>
      </Modal.Footer>
    </Modal>
  );
}

function App() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}