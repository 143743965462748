import React from "react";
import HomeGrid from './Components/HomeGrid';
import Footer from './Components/Footer';

export const Home = () => (
  <>
    <HomeGrid />
    <Footer />
  </>
);
