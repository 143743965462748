import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from './images/logoconv.png';

const MyNavbar = () => {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-white mx-auto">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src={logo}
            alt="OndaSonica"
            height="70"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Головна</Nav.Link>
            <Nav.Link as={Link} to="/About">Про нас</Nav.Link>
            <NavDropdown title="Деталі" id="collapsible-nav-dropdown">
              <NavDropdown.Item as={Link} to="/Service">Послуги</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Devices">Наші прилади</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/Contact">Контакти</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;
