import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal.css';
import appex from './images/appex.jpg';
import appex1 from './images/appex1.jpg';

export default function Modal4(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Appex - пілінг        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col sm={12}>
        <p>
        APPeex -ексфоліант нового покоління з регенеруючими властивостями. APPeex -застосовують для лікування інволюційно зміненої шкіри обличчя,
        з виразним меланостатичним ефектом. 
        За рахунок поєднання трихлороцтової кислоти з полінуклеотидами – APPeex має пряму дію на фібробласт, стимулюючи неоколагенез та неоангіогенез.
        </p>
        </Col>
        <Col sm={8}>
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div style={{ maxWidth: '200px', maxHeight: '325px', padding: '1%' }}>
      <img
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        src={appex}
        alt="Кабінет"
      />
    </div>
    <div style={{ maxWidth: '200px', maxHeight: '325px', padding: '1%' }}>
      <img
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        src={appex1}
        alt="Кабінет"
      />
    </div>
  </div>
</Col>
        <Col>
      <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Показання</Accordion.Header>
        <Accordion.Body>
        Фото - та хроностаріння<br/>
        Нерівний рельєф і текстура шкіри<br/>
        Гіперпігментація: хлоазма, мелазма<br/>
        Тьмяний колір шкіри<br/>
        Розширені пори<br/>
        Атрофічні та гіпертрофічні рубці<br/>
        Розтяжки та стрії<br/>
        Лікування вугревої хвороби<br/>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Протипоказання до застосування</Accordion.Header>
        <Accordion.Body>
        Онкологічні захворювання<br/>
        Вагітність та період лактації<br/>
        Інфекційні захворювання<br/>
        Запальні процеси на шкірі<br/>
        Алергія на компоненти пілінгу<br/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </Col>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Закрити</Button>
      </Modal.Footer>
    </Modal>
  );
}

function App() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}