import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import home from './images/Home.png';
import {ListGroup, ListGroupItem} from 'react-bootstrap';
import Iframe from 'react-iframe';
import inst from './images/inst.png';
import facebook from './images/facebook.png';
import Card from 'react-bootstrap/Card';
import tiktok from './images/tiktok.png';

export default function AboutGrid() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={8}>
          <img
            style={{ maxHeight: '400px', padding: '1%' }}
            className="d-block w-100"
            src={home}
            alt="Кабінет"
          />
        </Col>
        <Col xs={12} md={4} className="d-flex align-items-center">
        Сучасний медичний заклад, який було відкрито в 2023 році в місті Черкаси по вулиці Байди Вишневецького, 56 та у місті Одеса по вулиці Літературна, 8 (ЖК Жемчужина).
        <br/>Медичний центр який відомий своїми передовими технологіями ударно-хвильової терапії. 
        У нас працюють висококваліфіковані медичні спеціалісти, які забезпечують своїх пацієнтів індивідуальним підходом до лікування.
        Ми пропонуємо широкий спектр медичних послуг, включаючи діагностику та лікування захворювань, пов'язаних зі скелетно-м'язовою системою.
        </Col>
      </Row>
<h2 className='text-center'> Наші соціальні мережі </h2>
      <Row className="mb-4 flex-wrap">
        <Col xs={12} md={4} className="mb-4">
          <Card style={{ width: '100%' }}>
            <Card.Img variant="top" src={inst} style={{ height: '350px', objectFit: 'cover' }} />
            <Card.Body>
              <Card.Title className='text-center'><a href="https://www.instagram.com/onda.sonica?fbclid=IwAR2HG0tc2dZv2uWMx2HMLyouLHDERMCpWHHm2WeqEerVGSzOZl-2oUyvx_w" target="_blank" rel="noopener noreferrer">Instagram</a></Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4} className="mb-4">
          <Card style={{ width: '100%' }}>
            <Card.Img variant="top" src={facebook} style={{ height: '350px', objectFit: 'cover' }} />
            <Card.Body>
              <Card.Title className='text-center'><a href="https://www.facebook.com/profile.php?id=61554426881871" target="_blank" rel="noopener noreferrer">Facebook</a></Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4} className="mb-4">
          <Card style={{ width: '100%' }}>
            <Card.Img variant="top" src={tiktok} style={{ height: '350px', objectFit: 'cover' }} />
            <Card.Body>
            <Card.Title className='text-center'><a href="https://www.tiktok.com/@ondasonica.odesa?_t=8ji0A6haVsb&_r=1" target="_blank" rel="noopener noreferrer">Tik-Tok</a></Card.Title>            </Card.Body>
          </Card>
        </Col>
      </Row>

      <h2 className='text-center'>Ударно-хвильова терапія</h2>
      <Row>
        <Col xs={12} md={4} className="mb-4">
        <ul>
          <h5>Інноваційний метод лікування</h5>
          У наших медичних центрах використовується ударно-хвильова терапія – інноваційний метод лікування, що має широкий спектр застосувань.<br/>
          <strong>Цей метод дозволяє:</strong>
          <li>Розбивати камені в нирках без хірургічного втручання.</li>
          <li>Лікувати ревматоїдний артрит, зменшуючи біль і запалення.</li>
          <li>Усувати запальні процеси в м'язах, сухожиллях та інших тканинах.</li>
          <li>Лікувати травми та прискорювати загоєння.</li>
          <li>Зменшувати біль у м'язах і суглобах.</li>
          </ul>
        </Col>
        <Col xs={12} md={4} className="mb-4">
        <ul>
        <h5>Переваги УХТ:</h5><strong></strong>
          <li><strong>Безпечність:</strong> метод неінвазивний, не потребує хірургічного втручання.</li>
          <li><strong>Ефективність:</strong> дає стійкий результат при правильному підборі параметрів.</li>
          <li><strong>Швидкість:</strong> процедура зазвичай займає 10-20 хвилин.</li>
          <li><strong>Відсутність побічних ефектів:</strong> метод безпечний для більшості пацієнтів.</li>
          <strong> Ми пропонуємо:</strong>
          <li><strong>Високоякісні послуги:</strong> використовуємо сучасні медичні технології та обладнання.</li>
          <li><strong>Досвідчених фахівців:</strong> наші лікарі мають великий досвід роботи з ударно-хвильовою терапією.</li>
          </ul>
        </Col>
        <Col xs={12} md={4} className="mb-4">
        <ul>
        <h5>Революція в косметології</h5><strong></strong>
        <li><strong>Бороться з віковими змінами:</strong> розгладжує зморшки, підтягує шкіру обличчя та тіла.</li>
        <li><strong>Лікує целюліт:</strong> розбиває жирові відкладення, роблячи шкіру більш гладенькою та пружною.</li>
        <li><strong>Видаляє розтяжки:</strong> стимулює вироблення колагену та еластину.</li>
        <li><strong>Лікує рубці:</strong> покращує кровообіг, розм'якшуває рубці та робить їх менш вираженими.</li>
        <li><strong>Зменшує набряки:</strong> покращуває лімфоток, знімає набряки під очима та в інших зонах.</li>
        </ul>
        </Col>
      </Row>

      {/* Columns are always 50% wide, on mobile and desktop */}
      <Row>
      <Col md={6}>
          <Card>
            <Card.Header as="h5">Черкаси</Card.Header>
            <Card.Body>
              <ListGroup className="mb-2">
                <ListGroupItem><a href="tel:+380677621000">+38(067)762-10-00</a></ListGroupItem>
                <ListGroupItem><a href="tel:+380507621000">+38(050)762-10-00</a></ListGroupItem>
              </ListGroup>
              <Iframe
                url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2594.2518835774013!2d32.052320212423844!3d49.44195615934282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d14d38fd345795%3A0xcd9a1d4a520369d0!2sOndaSonica!5e0!3m2!1suk!2sua!4v1706615568744!5m2!1suk!2sua"
                width="100%"
                height="300px"
                display="initial"
                position="relative"
                allowFullScreen
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Header as="h5">Одеса</Card.Header>
            <Card.Body>
              <ListGroup className="mb-2">
                <ListGroupItem><a href="tel:+380985048080">+38(098)504-80-80</a></ListGroupItem>
                <ListGroupItem><a href="tel:+380995048080">+38(099)504-80-80</a></ListGroupItem>
              </ListGroup>
              <Iframe
                url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2750.3773189913!2d30.754823912292558!3d46.4214003689453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c63574bb167e6b%3A0xaa9898f92eacc832!2sOndaSonica!5e0!3m2!1suk!2sua!4v1706615895064!5m2!1suk!2sua"
                width="100%"
                height="300px"
                display="initial"
                position="relative"
                allowFullScreen
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
